import React from 'react';
import { FiGitBranch } from 'react-icons/fi';
import { BiTestTube } from 'react-icons/bi';

const Hero = () => {
    const features = [
        {
            name: 'Cypress test results',
            description: (
                <a
                    href={'http://cypress-dtc-2139818232.us-east-2.elb.amazonaws.com/'}
                    className="text-orange-500 hover:underline"
                >
                    View latest tests runs on DTC front end
                </a>
            ),
            icon: BiTestTube,
        },
        {
            name: 'Feature branches',
            description: (
                <>
                    <p className="mt-3 mx-auto text-gray-500">
                        Edit URL in following format to go visit branch deployment:
                    </p>
                    <h4 className="py-2 text-sm">https://dtc-dev.ordermarkdev.com/ + &#123; branch name &#125;</h4>
                </>
            ),
            icon: FiGitBranch,
        },
    ];

    return (
        <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
                <section>
                    <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl"></p>
                </section>
                <div className=" bg-white text-left">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="mt-10">
                            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                                {features.map(feature => (
                                    <div key={feature.name} className="relative">
                                        <dt>
                                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                                                <feature.icon
                                                    className="h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                                                {feature.name}
                                            </p>
                                        </dt>
                                        <dd className="mt-2 ml-16 text-base text-gray-500">
                                            {feature.description}
                                        </dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
