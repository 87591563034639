import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import history from "./utils/history";
import Home from "./pages/Home";
import Profile from "./pages/Profile";

function App() {
  const { isLoading, error } = useAuth0();

  if (error) {
    return (
      <div className="flex flex-col h-screen justify-center items-center">
        <div className="m-8">Oops... {error.message}</div>
        <div className="m-8">This error page can be customized </div>
        <button
          className="bg-orange-500 px-4 py-2 rounded-md text-white"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </button>
      </div>
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <div className="flex-grow mt-5">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
