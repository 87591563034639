import React from 'react';
import Highlight from '../components/Highlight';
import Loading from '../components/Loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

export const ProfileComponent = () => {
    const { user } = useAuth0();

    return (
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
        </div>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <Loading />,
});
