import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const NavBar = () => {
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { isAuthenticated, loginWithPopup, logout, loginWithRedirect } = useAuth0();
    const pathname = window.location.pathname;

    const navigation = [{ name: 'Account', href: '/profile' }];

    return (
        <div className="p-6 overflow-hidden flex-wrap flex-column items-center justify-center">
            <section className="flex justify-center w-full mb-8">
                <Link onClick={forceUpdate} to="/">
                    <img
                        className="h-8 w-auto"
                        src="https://www.nextbite.io/wp-content/uploads/2021/10/Nextbite-Logo-Orange.png"
                        alt=""
                    />
                </Link>
            </section>

            <section className="flex justify-center items-center">
                <div className="md:flex md:space-x-10">
                    {isAuthenticated && pathname !== '/profile' &&
                        navigation.map(item => (
                            <div className="mb-8" key={item.name}>
                                <Link
                                    onClick={forceUpdate}
                                    to={item.href}
                                    className="rounded-md shadow items-center px-4 py-2 border border-transparent text-base font-medium  text-orange-600 bg-white hover:text-indigo-500"
                                >
                                    {item.name}
                                </Link>
                            </div>
                        ))}
                </div>
            </section>

            <section className="flex justify-center items-center">
                {isAuthenticated ? (
                    <Link
                        onClick={() => {
                            logout({
                                returnTo: window.location.origin,
                            })
                        }}
                        to="#"
                        className="rounded-md shadow items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-orange-600 bg-white hover:text-indigo-500"
                    >
                        Log out
                    </Link>
                ) : (
                    <>
                        <span className="inline-flex rounded-md shadow mr-4">
                            <Link
                                onClick={() => loginWithPopup()}
                                to="#"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-orange-600 bg-white hover:text-indigo-500"
                            >
                                Log in with Popup
                            </Link>
                        </span>
                        <span className="inline-flex rounded-md shadow">
                            <Link
                                onClick={() => loginWithRedirect()}
                                to="#"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium text-orange-600 bg-white hover:text-indigo-500"
                            >
                                Log in with Redirect
                            </Link>
                        </span>
                    </>
                )}
            </section>
        </div>
    );
};

export default NavBar;
